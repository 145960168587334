<template>
  <div
    class="marketplace"
    :class="{ 'marketplace_viewtype_full': full }"
  >
    <svg-icon
      :name="iconName"
      :class="classes"
    />
    <div
      v-if="full"
      class="marketplace__desc"
    >
      <div class="marketplace__shop">
        {{ shop }}
      </div>
      <div class="marketplace__name">
        {{ formattedName }}
      </div>
    </div>
  </div>
</template>

<script>
import marketplaces from '@/constants/marketplace-name'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    shop: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    iconName () {
      return this.full || this.small ? this.name : `${this.name}--full`
    },

    classes () {
      const classes = ['marketplace__icon']
      if (this.small) {
        classes.push('marketplace__icon_viewtype_small')
      }

      return classes
    },

    formattedName () {
      return marketplaces[this.name]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .marketplace
    &_viewtype_full
      display flex
      align-items center
      padding-right 68px

      .marketplace__icon
        display block
        flex-shrink 0
        flex-grow 0
        width 25px
        height 25px
        max-width 100%
        max-height none
        margin-right 3px

    &__icon
      max-width 70px
      max-height 20px

      &_viewtype_small
        width 11px
        max-width 100%
        height 11px
        max-height none

    &__desc
      text-align left

    &__shop
      font-size 14px
      color #3c3c47
      white-space nowrap

    &__name
      font-size 10px
      white-space nowrap
</style>
