<template>
  <x-page
    title="Магазины"
    card
  >
    <div class="marketplaces">
      <div
        v-if="!user.shop_count && !isManager"
        class="marketplaces__promo mb-3"
      >
        <img src="@/assets/images/market/pic_store.png">
        <div class="marketplaces__text pr-3 pl-3">
          <div class="headline-4 mb-1">
            {{ titleText }}
          </div>
          <div>Вы узнаете на каком месте ваши товары в поисковой выдаче, как вывести карточки в топ и многое другое</div>
        </div>
        <x-btn
          class="ml-auto"
          prepend="plus"
          color="white"
          @click="onAddShopClick"
        >
          Добавить магазин
        </x-btn>
      </div>
      <div
        v-if="!isManager"
        class="primary-lightest-2 p-4 mb-6 rounded-2"
      >
        <div
          v-if="!user.shop_count"
          class="headline-5 mb-5"
        >
          После добавления магазина вы узнаете:
        </div>
        <div class="marketplaces__cards">
          <div
            v-for="item in marketplacesPromo"
            :key="item.id"
          >
            <x-advice-card :src="item.img">
              <template #title>
                <div class="headline-6 font-weight-bold">
                  {{ item.title }}
                </div>
              </template>

              <x-icon
                v-for="icon in item.mp_icons"
                :key="icon"
                class="mr-2"
                :name="icon"
              />

              <template #actions>
                <x-advice-card-btn
                  v-if="user.shop_count"
                  :href="item.to"
                  :metrics="item.metrics"
                  size="xs"
                  class="card__link"
                >
                  <div v-html="item.link" />
                </x-advice-card-btn>
              </template>
            </x-advice-card>
          </div>
        </div>
      </div>
      <shop-limit-alert
        v-if="!isManager"
        class="mb-2"
      />
      <market-info
        :is-loading="disableSubmitButton"
        :invalid-message="invalidMessage"
        @save-changes="submitChanges"
      />

      <div class="marketplaces__content">
        <div
          v-if="isLoading"
          class="marketplaces__loader"
        >
          <loader />
        </div>

        <shops-list
          v-else
          :shops="shops"
          :pagination="pagination"
          @refresh-shops="getShops"
        />
      </div>

      <create-store-request-modal />
    </div>
  </x-page>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Loader from '@/components/Common/Loader.vue'
import ShopsList from '@/components/Marketplaces/ShopsList.vue'
import marketplacesPromo from '@/constants/marketplaces-promo.js'
import MarketInfo from '@/components/Marketplaces/Modals/MarketInfo.vue'
import CreateStoreRequestModal from '@/components/Modals/CreateStoreRequestModal.vue'
import { TARIFF } from '@/constants/tariffs'
import ShopLimitAlert from '@/components/NotPermit/ShopLimitAlert'

const { mapActions, mapState } = createNamespacedHelpers('marketplaces')
const { mapState: profileMapState, mapGetters: profileGetters } = createNamespacedHelpers('profile')

export default {
  components: {
    ShopLimitAlert,
    Loader,
    ShopsList,
    MarketInfo,
    CreateStoreRequestModal
  },

  data () {
    return {
      isLoading: true,
      disableSubmitButton: false,
      marketplacesPromo,
      invalidMessage: {},
      // shops: [],
      pagination: null
    }
  },

  computed: {
    ...mapState(['shops']),
    ...profileMapState(['user']),
    ...profileGetters(['isPromoUser', 'currentTariff']),
    titleText () {
      if (this.currentTariff.tariff.key === TARIFF.TEST.key ||
        this.currentTariff.tariff.key === TARIFF.FREE.key) {
        return 'Добавьте магазин и получите 7 дней платного тарифа!⚡'
      }
      return 'Добавьте свой магазин!⚡'
    },
    isManager () {
      return this.user.service_model && this.user.service_model === 'xway-service'
    }
  },

  created () {
    this.getShops()
  },

  methods: {
    ...mapActions(['getShopListV2', 'updateShopV2']),

    async getShops (page = 1, limit = 10) {
      try {
        this.isLoading = true
        const payload = {
          page: page,
          limit: limit
        }
        const { data, pagination } = await this.getShopListV2(payload)
        if (data) {
          this.pagination = { ...this.pagination, ...pagination }
          // this.shops = data
        }
      } finally {
        this.isLoading = false
      }
    },

    onAddShopClick () {
      this.$metrics.hit(this.$route.meta?.metricsEvents?.addShop?.add)

      if (this.isPromoUser) {
        this.$modal.show('connect-modal', { formName: 'connect-form-ali' })
      } else {
        this.$modal.show('add-marketplace-modal')
      }
    },

    // eslint-disable-next-line camelcase
    async submitChanges ({ shop, is_active, id, credentials, sync_daily_report }) {
      try {
        this.disableSubmitButton = true
        const res = await this.updateShopV2({
          id,
          name: shop,
          is_active,
          credentials,
          sync_daily_report: sync_daily_report
        })
        this.$toast.success('Успешно сохранено')
        this.$modal.hide('market-info')
        this.invalidMessage = {}
        const { data, pagination } = await this.getShopListV2({ page: this.pagination.page, limit: this.pagination.pageSize })
        if (data) {
          this.pagination = { ...this.pagination, ...pagination }
          // this.shops = data
        }
      } catch (error) {
        this.invalidMessage = error.response?.data.message
      } finally {
        this.disableSubmitButton = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .marketplaces
    &__promo
      display flex
      align-items center
      background linear-gradient(90deg, #5B40FF 0%, #A040FF 100%)
      border-radius 8px
      padding 12px 24px
      color #fff
      flex-wrap wrap
    &__cards
      display grid
      grid-template-columns repeat(4, minmax(200px, 1fr))
      grid-gap 12px
      @media (max-width 1200px)
        grid-template-columns repeat(2, minmax(200px, 1fr))
      @media (max-width 768px)
        grid-template-columns 1fr

    &__loader
      text-align center

  .card__link
    height auto
</style>
