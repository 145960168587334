<template>
  <div class="shop">
    <div class="shop__in">
      <template v-if="isObject">
        <template v-if="Object.keys(shop).length">
          <div
            v-if="showMarketplace"
            class="shop__marketplace"
          >
            <svg-icon
              class="shop__marketplace-icon"
              :name="shop.marketplace"
            />

            {{ shop.marketplaceName }}:
          </div>
          <span
            v-if="!small"
            class="shop__title"
          >
            {{ shop.name }}
          </span>
        </template>

        <template v-else>
          <svg-icon
            name="error-triangle"
            class="shop__icon"
          />
          <span class="shop__title shop__title_small">Магазин не задан</span>
        </template>
      </template>

      <template v-else>
        <span
          v-if="!small"
          class="shop__title"
        >
          {{ shop }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shop: {
      type: [Object, String],
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    showMarketplace: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isObject () {
      return typeof this.shop === 'object'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .shop
    display inline-block

    &__in,
    &__marketplace
      display flex
      align-items center

    &__title
      &_small
        font-size 14px

    &__icon
      width 14px
      height 12px
      margin-right 5px
      fill #f3bb1c

    &__marketplace
      margin-right 4px
      font-size 14px
      color #7b7b85

    &__marketplace-icon
      width 14px
      height 14px
      margin-right 4px
</style>
