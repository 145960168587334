var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"shops-list__header"},[_c('heading',{staticClass:"shops-list__title",attrs:{"tag":"h3"}},[_vm._v(" Список магазинов ")]),(_vm.$currentDevice.isMobile && _vm.isShopActionsAccessed(_vm.row))?_c('grid-row-action-menu',{attrs:{"bind-to":".shops-list","placement":"bottom-start","actions":_vm.listActions}},[_c('x-btn',{attrs:{"outline":"","ghost":"","icon":"dots-vertical"}})],1):(_vm.shops.length && _vm.isUserAdmin)?_c('ul',{staticClass:"shops-list__actions"},[_c('li',{staticClass:"shops-list__action"},[_c('x-btn',{attrs:{"outline":"","prepend":"plus","type":"button"},on:{"click":_vm.onAddShopClick}},[_vm._v(" Добавить магазин ")])],1)]):_vm._e()],1),_c('grid',{staticClass:"shops-list__grid",attrs:{"auto":"","columns":_vm.columns,"cells":_vm.shops,"actions":_vm.actions},on:{"cell-clicked":_vm.openMarketInfoModal},scopedSlots:_vm._u([{key:"shop",fn:function(ref){
var shop = ref.shop;
return [_c('shop',{attrs:{"shop":shop}})]}},{key:"marketplace",fn:function(ref){
var row = ref.row;
return [_c('marketplace',{attrs:{"name":row.marketplace_code}})]}},{key:"split_number",fn:function(ref){
var split_number = ref.split_number;
return [_c('span',[_vm._v(_vm._s((split_number || 0).toLocaleString('ru')))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('badge',{attrs:{"status":_vm.getShopStatus(row)}})]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [(!_vm.$currentDevice.isMobile && _vm.isShopActionsAccessed(row))?_c('grid-row-action-menu',{attrs:{"actions":_vm.makeRowActions(row),"row":row}}):_c('span')]}},{key:"auth",fn:function(ref){
var row = ref.row;
return [(_vm.errorStatuses[row.authorization_status] || _vm.errorStatuses[row.wb_inner_auth_state])?_c('div',{staticClass:"marketplace__auth-error"},[_c('svg-icon',{staticClass:"marketplace__auth",attrs:{"name":"error-triangle"}}),_c('tooltip',{staticClass:"marketplace__auth-info",attrs:{"icon":"info-fill"},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_vm._v(" "+_vm._s(_vm.errorStatuses[row.authorization_status] || _vm.errorStatuses[row.wb_inner_auth_state])+" ")]},proxy:true}],null,true)})],1):_c('svg-icon',{staticClass:"marketplace__auth",attrs:{"name":_vm.isAuthenticated(row) ? 'ok': 'close'}})]}},{key:"sync",fn:function(ref){
var row = ref.row;
return [_c('svg-icon',{staticClass:"marketplace__auth",attrs:{"name":row.sync_daily_report ? 'ok': 'close'}})]}}])}),(_vm.pagination && _vm.pagination.totalPages > 1)?_c('div',{staticClass:"marketplace__pagination"},[_c('pagination',{attrs:{"data":_vm.pagination,"current-page":_vm.currentPage},on:{"change":_vm.onPaginationChange}})],1):_vm._e(),(!_vm.shops.length)?_c('div',{staticClass:"shops-list__placeholder"},[(_vm.isUserAdmin)?_c('x-placeholder',{attrs:{"icon":"no-data"},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_vm._v(" У вас пока не добавлено ни одного магазина."),_c('br'),_vm._v(" Добавьте свой первый магазин ")]},proxy:true},{key:"title",fn:function(){return [_c('div',{staticClass:"headline-4 font-weight-bold"},[_vm._v(" Нет данных ")])]},proxy:true},{key:"bottom",fn:function(){return [_c('x-btn',{attrs:{"prepend":"plus"},on:{"click":_vm.onAddShopClick}},[_vm._v(" Добавить магазин ")])]},proxy:true}],null,false,3749154968)}):_c('x-placeholder',{attrs:{"icon":"table-empty","desc":"У вас нет доступа ни к одному из магазинов"}})],1):_vm._e(),_c('add-marketplace-modal'),_c('connect-modal',{on:{"success":_vm.onShopConnected}}),_c('ali-auth-modal'),_c('ozon-auth-modal'),_c('wildberries-auth-modal'),_c('two-fa-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }