import codes from './marketplace-codes'
import names from './marketplace-name'

export default [{
  label: names.ali,
  value: codes.ALI
}, {
  label: names.goods,
  value: codes.SBER
}, {
  label: names.ozon,
  value: codes.OZON
}, {
  label: names.wildberries,
  value: codes.WILDBERRIES
}, {
  label: names.yandex,
  value: codes.YANDEX
}]
