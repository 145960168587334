import { render, staticRenderFns } from "./Marketplace.vue?vue&type=template&id=216dbe60&scoped=true&"
import script from "./Marketplace.vue?vue&type=script&lang=js&"
export * from "./Marketplace.vue?vue&type=script&lang=js&"
import style0 from "./Marketplace.vue?vue&type=style&index=0&id=216dbe60&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216dbe60",
  null
  
)

export default component.exports