<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    width="90%"
    height="auto"
    :adaptive="true"
    :click-to-close="false"
    @before-open="onBeforeOpen"
    @closed="onClosed"
  >
    <div class="modal-container">
      <validation-observer
        v-if="!isFormSent"
        v-slot="{ handleSubmit }"
      >
        <heading
          level="3"
          class="mt-6"
        >
          {{ title }}
        </heading>

        <the-form
          class="form_content_connect-marketplace"
          @submit="handleSubmit(onSubmit)"
        >
          <fieldset class="form-fields-group">
            <ul class="form-fields-list">
              <template v-if="step === 1">
                <li class="form-fields-list__item form-fields-list__item_content_desc">
                  Введите номер телефона магазина на Ozon.
                </li>

                <li class="form-fields-list__item">
                  <phone-field
                    v-model="phone"
                    placeholder="+7 987 654 32 10"
                  />
                </li>

                <x-btn
                  v-if="isCodeRequested"
                  text
                  @click="step = 2"
                >
                  Ввести код
                </x-btn>
              </template>

              <template v-if="step === 2 || step === 4" >
                <li
                  v-if="step === 2 "
                  class="form-fields-list__item form-fields-list__item_content_desc"
                >
                  Введите код полученный в СМС сообщении или в приложении Ozon, или из e-mail.
                </li>
                <li
                  v-else
                  class="form-fields-list__item form-fields-list__item_content_desc"
                >
                  Введите второй код с почты или смс.
                </li>

                <li class="form-fields-list__item">
                  <x-form-item
                    v-slot="validationData"
                    label="Код"
                    name="Код"
                    rules="required"
                  >
                    <x-input
                      v-model="code"
                      v-mask="'######'"
                      v-bind="validationData"
                      placeholder="Код"
                    />
                  </x-form-item>
                </li>

                <p v-if="resendText">
                  {{ resendText }}
                </p>
                <x-btn
                  v-else
                  type="button"
                  text
                  @click="resendCode"
                >
                  Отправить повторно
                </x-btn>
              </template>

              <template v-if="step === 3">
                <li class="form-fields-list__item">
                  <x-form-item
                    v-slot="validationData"
                    label="Выберите магазин"
                    name="Выберите магазин"
                    rules="required"
                  >
                    <x-select
                      v-model="selectedShop"
                      :options="shops"
                      track-by="id"
                      label="name"
                      v-bind="validationData"
                      placeholder="Магазин"
                    />
                  </x-form-item>
                </li>
              </template>

              <li class="form-fields-list__item form-fields-list__item_content_submit">
                <ul class="modal-container__actions">
                  <li class="modal-container__action">
                    <x-btn
                      type="button"
                      outline
                      block
                      @click="$modal.hide(modalName)"
                    >
                      Отмена
                    </x-btn>
                  </li>

                  <li class="modal-container__action">
                    <x-btn
                      type="submit"
                      block
                      :disabled="isSubmitDisabled"
                      :loading="isLoading"
                    >
                      Отправить
                    </x-btn>
                  </li>
                </ul>
              </li>
            </ul>
          </fieldset>
        </the-form>
      </validation-observer>

      <success-connection
        v-else
        heading="Доступ получен!"
        text=""
        @click="onFinishClick"
      />
    </div>
  </modal>
</template>

<script>
import Heading from '@/components/Common/Heading'
import TheForm from '@/components/Interface/Form.vue'
import PhoneField from '@/components/Interface/PhoneField'
import SuccessConnection from '@/components/Marketplaces/ConnectForms/SuccessConnection.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('marketplaces')

const baseTimerDuration = 120

export default {
  components: {
    Heading,
    PhoneField,
    TheForm,
    SuccessConnection
  },

  data () {
    return {
      modalName: 'ozon-auth-modal',
      id: null,
      isCodeRequested: false,
      isFormSent: false,
      isLoading: false,
      phone: '+7',
      code: '',
      step: 1,
      shops: [],
      selectedShop: null,
      timer: baseTimerDuration
    }
  },

  watch: {
    step (value) {
      if (value === 2) {
        this.isCodeRequested = true
        this.startResendTimer()
      }
      if (value === 3) {
        this.isCodeRequested = false
        this.getShopsList()
      }
    }
  },

  computed: {
    isSubmitDisabled () {
      if (this.step === 1) return this.phone.length < 18
      if (this.step === 2) return this.code.length < 6
      if (this.step === 3) return this.selectedShop === null
      return false
    },

    computedPhone () {
      return this.phone.replace(/[+()-/\s]/g, '')
    },

    resendText () {
      return this.timer < baseTimerDuration ? `Запросить код повторно можно через ${this.timer} секунд` : ''
    },

    title () {
      return this.step === 3 ? 'Выбор магазина' : 'Доступ к личному кабинету'
    }
  },

  methods: {
    ...mapActions([
      'requestOzon2FACode',
      'sendOzon2FACode',
      'getOzonShopList',
      'attachOzonShop'
    ]),

    async onBeforeOpen (e) {
      if (e.params) {
        const { id } = e.params

        if (id) this.id = id
      }
    },

    onClosed () {
      this.code = ''
      this.step = 1
      this.shops = []
      this.selectedShop = null
      this.isFormSent = false
    },

    startResendTimer () {
      const interval = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          clearInterval(interval)
          this.timer = baseTimerDuration
        }
      }, 1000)
    },

    onSubmit () {
      if (this.id) {
        if (this.step === 1) {
          this.requestCode()
        }

        if (this.step === 2 || this.step === 4) {
          this.sendCode()
        }

        if (this.step === 3) {
          this.attachShop()
        }
      }
    },

    async resendCode () {
      await this.requestCode()
      this.startResendTimer()
    },

    async requestCode () {
      this.isLoading = true
      const result = await this.requestOzon2FACode({ id: this.id, user: this.computedPhone })
      this.step = 1
      this.step = result[0]
      this.session_id = result[1]
      this.isLoading = false
    },

    async sendCode () {
      this.isLoading = true
      const result = await this.sendOzon2FACode({ id: this.id, user: this.computedPhone, sms_code: this.code, session_id: this.session_id })
      this.step = result[0]
      this.session_id = result[1]
      if (this.step === 4) {
        this.code = ''
      }
      this.isLoading = false
    },

    async getShopsList () {
      this.isLoading = true
      this.shops = await this.getOzonShopList({ user: this.computedPhone })
      this.isLoading = false
    },

    async attachShop () {
      this.isLoading = true
      this.isFormSent = await this.attachOzonShop({ id: this.id, user: this.computedPhone, company_id: this.selectedShop.id })
      this.isLoading = false
    },

    onFinishClick () {
      this.phone = ''
      this.session_id = ''
      this.isCodeRequested = false
      this.$modal.hide(this.modalName)
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-container
  max-width 390px
  margin 0 auto
  padding 20px 15px 60px
  box-sizing border-box

  @media (min-width 768px)
    padding 20px 0 60px

  &__actions
    display flex
    justify-content space-between
    margin 0
    padding 0
    list-style none

  &__action
    flex-basis 48%

/deep/ .vm--modal
  overflow visible
</style>
