<template>
  <div class="edit-buttons">
    <x-btn
      type="button"
      color="gray"
      class="edit-buttons__dismiss"
      @click="$emit('dismiss')"
    >
      Отменить
    </x-btn>

    <x-btn
      class="edit-buttons__save"
      type="submit"
      :loading="isLoading"
      :disabled="disabled"
    >
      Сохранить
    </x-btn>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="stylus" scoped>
  .edit-buttons
    display grid
    grid-template-columns 45% 45%
    column-gap 10%
    margin-top 50px

    @media (max-width 768px)
      grid-template-columns 48% 48%
      column-gap 4%
</style>
