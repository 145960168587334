<template>
  <div>
    <header class="shops-list__header">
      <heading
        tag="h3"
        class="shops-list__title"
      >
        Список магазинов
      </heading>

      <grid-row-action-menu
        v-if="$currentDevice.isMobile && isShopActionsAccessed(row)"
        bind-to=".shops-list"
        placement="bottom-start"
        :actions="listActions"
      >
        <x-btn
          outline
          ghost
          icon="dots-vertical"
        />
      </grid-row-action-menu>

      <ul
        v-else-if="shops.length && isUserAdmin"
        class="shops-list__actions"
      >
        <li class="shops-list__action">
          <x-btn
            outline
            prepend="plus"
            type="button"
            @click="onAddShopClick"
          >
            Добавить магазин
          </x-btn>
        </li>
      </ul>
    </header>

    <grid
      auto
      :columns="columns"
      :cells="shops"
      :actions="actions"
      class="shops-list__grid"
      @cell-clicked="openMarketInfoModal"
    >
      <template #shop="{ shop }">
        <shop :shop="shop" />
      </template>

      <template #marketplace="{ row }">
        <marketplace :name="row.marketplace_code" />
      </template>

      <template #split_number="{ split_number }">
        <span>{{ (split_number || 0).toLocaleString('ru') }}</span>
      </template>

      <template #status="{ row }">
        <badge :status="getShopStatus(row)" />
      </template>

      <template #actions="{ row }">
        <grid-row-action-menu
          v-if="!$currentDevice.isMobile && isShopActionsAccessed(row)"
          :actions="makeRowActions(row)"
          :row="row"
        />
        <span v-else /> <!-- hide default actions slot -->
      </template>

      <template #auth="{ row }">
        <div
          v-if="errorStatuses[row.authorization_status] || errorStatuses[row.wb_inner_auth_state]"
          class="marketplace__auth-error"
        >
          <svg-icon
            name="error-triangle"
            class="marketplace__auth"
          />
          <tooltip
            icon="info-fill"
            class="marketplace__auth-info"
          >
            <template #popover>
              {{ errorStatuses[row.authorization_status] || errorStatuses[row.wb_inner_auth_state] }}
            </template>
          </tooltip>
        </div>

        <svg-icon
          v-else
          class="marketplace__auth"
          :name="isAuthenticated(row) ? 'ok': 'close'"
        />
      </template>

      <template #sync="{ row }">
        <svg-icon
          class="marketplace__auth"
          :name="row.sync_daily_report ? 'ok': 'close'"
        />
      </template>
    </grid>

    <div
      v-if="pagination && pagination.totalPages > 1"
      class="marketplace__pagination"
    >
      <pagination
        :data="pagination"
        :current-page="currentPage"
        @change="onPaginationChange"
      />
    </div>

    <div
      v-if="!shops.length"
      class="shops-list__placeholder"
    >
      <x-placeholder
        v-if="isUserAdmin"
        icon="no-data"
      >
        <template #desc>
          У вас пока не добавлено ни одного магазина.<br>
          Добавьте свой первый магазин
        </template>
        <template #title>
          <div class="headline-4 font-weight-bold">
            Нет данных
          </div>
        </template>
        <template #bottom>
          <x-btn
            prepend="plus"
            @click="onAddShopClick"
          >
            Добавить магазин
          </x-btn>
        </template>
      </x-placeholder>

      <x-placeholder
        v-else
        icon="table-empty"
        desc="У вас нет доступа ни к одному из магазинов"
      />
    </div>

    <add-marketplace-modal />
    <connect-modal @success="onShopConnected" />
    <ali-auth-modal />
    <ozon-auth-modal />
    <wildberries-auth-modal />
    <two-fa-modal />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import marketplaceCode from '@/constants/marketplace-codes'
import { columns, authStatuses, wildberriesAuthStatuses } from '@/constants/shops'
import Heading from '@/components/Common/Heading.vue'
import Grid from '@/components/Interface/Grid.vue'
import Pagination from '@/components/Interface/Pagination.vue'
import Marketplace from '@/components/Common/Marketplace.vue'
import Shop from '@/components/Common/Shop.vue'
import Badge from '@/components/Common/Badge.vue'
import GridRowActionMenu from '@/components/Interface/GridRowActionMenu.vue'
import AliAuthModal from '@/components/Modals/AliAuthModal.vue'
import OzonAuthModal from '@/components/Modals/OzonAuthModal'
import WildberriesAuthModal from '@/components/Modals/WildberriesAuthModal'
import ConnectModal from '@/components/Modals/ConnectModal.vue'
import AddMarketplaceModal from '@/components/Modals/AddMarketplaceModal.vue'
import Tooltip from '@/components/Common/Tooltip.vue'
import TwoFaModal from '@/components/Modals/2FAModal.vue'
import NotPermitModal from '@/components/NotPermit/NotPermitModal'
import PaginationMixin from '@/mixins/pagination'

const { mapActions } = createNamespacedHelpers('marketplaces')
const { mapGetters: profileGetters } = createNamespacedHelpers('profile')

export default {
  mixins: [PaginationMixin],
  components: {
    WildberriesAuthModal,
    Heading,
    Grid,
    Pagination,
    Marketplace,
    Shop,
    Badge,
    GridRowActionMenu,
    AliAuthModal,
    OzonAuthModal,
    ConnectModal,
    AddMarketplaceModal,
    Tooltip,
    TwoFaModal
  },

  props: {
    shops: {
      type: Array,
      default: () => [],
      required: true
    },
    pagination: {
      type: Object || null,
      default: () => {}
    }
  },

  data () {
    return {
      columns,
      errorStatuses: {
        'wrong_password': 'Неверный логин\\пароль, пожалуйста пройдите авторизацию повторно',
        'requires_2fa': 'Пожалуйста, пройдите двухфакторную авторизацию',
        'INACTIVE': 'Срок действия токена истек'
      },
      actions: [
        {
          label: 'Редактировать',
          cb: cell => this.openMarketInfoModal({ cell, columns })
        }
      ],
      listActions: [{
        iconName: 'plus',
        label: 'Добавить магазин',
        cb: this.onAddShopClick
      }, {
        iconName: 'plus',
        label: 'Создать магазин',
        cb: () => this.$modal.show('create-store-request-modal')
      }, {
        iconName: 'stop',
        label: 'Отмена'
      }]
    }
  },

  computed: {
    ...profileGetters([
      'isPromoUser',
      'isUserAdmin',
      'isUserAccessedToSection'
    ]),
    currentPage () {
      return this.pagination.page
    }
  },

  mounted () {
    if (this.$route.query.success) {
      this.$modal.show('connect-modal', {
        formName: 'connect-form-ali',
        success: true
      })
      this.$router.replace({ query: undefined })
    }

    if (this.$route.query.add) {
      this.$router.replace({ query: undefined })
      this.onAddShopClick()
    }
  },

  methods: {
    ...mapActions([
      'logoutMarketplace',
      'detachWildberriesShop'
    ]),

    openMarketInfoModal (shop) {
      if (shop.cell.is_paywalled_in_lkp) {
        this.$dialog.show(NotPermitModal)
        return
      }
      if (!this.isShopActionsAccessed(shop.cell)) return
      this.$modal.show('market-info', shop)
    },

    onAddShopClick () {
      this.$metrics.hit(this.$route.meta?.metricsEvents?.addShop?.add)

      if (this.isPromoUser) {
        this.$modal.show('connect-modal', { formName: 'connect-form-ali' })
      } else {
        this.$modal.show('add-marketplace-modal')
      }
    },

    onShopConnected () {
      this.$modal.hide('connect-modal', { formName: 'connect-form-ali' })
      this.$modal.hide('add-marketplace-modal')
      this.$emit('refresh-shops', this.currentPage, this.pagination.limit)
    },

    onPaginationChange ({ page, limit, offset }) {
      this.pagination.limit = limit

      this.$emit('refresh-shops', page, limit)
    },

    getShopStatus (shop) {
      const status = shop.is_active ? 'active' : 'disable'

      return shop.has_valid_token ? status : 'wrong_token'
    },

    isShopActionsAccessed (shop) {
      return this.isUserAccessedToSection({
        section: 'shop_control',
        shopId: shop.id
      })
    },

    isAuthenticated (shop) {
      if (shop.marketplace_code === marketplaceCode.ALI) return shop.is_enable_ali_access_data
      if (shop.marketplace_code === marketplaceCode.OZON) return shop.ozon_inner_auth_state === 'ACTIVE'
      if (shop.marketplace_code === marketplaceCode.WILDBERRIES) return shop.wb_inner_auth_state === wildberriesAuthStatuses.ACTIVE.key
      return false
    },

    makeRowActions (row) {
      const actions = this.actions.slice()
      const logoutAction = {
        iconName: 'log-out',
        label: 'Выйти',
        cb: async (cell) => await this.logoutMarketplace(cell.id)
      }

      if (row.marketplace_code === marketplaceCode.ALI) {
        const status = row.authorization_status

        if (row.is_enable_ali_access_data && status === authStatuses.success) {
          actions.push(logoutAction)
        } else {
          const modal = status === authStatuses.requires2FA ? '2fa-modal' : 'ali-auth-modal'
          actions.push({
            iconName: 'log-out',
            label: 'Авторизоваться',
            cb: cell => this.$modal.show(modal, { id: cell.id })
          })
        }
      }

      if (row.marketplace_code === marketplaceCode.OZON) {
        if (row.credentials_exists) {
          actions.push(logoutAction)
        } else {
          actions.push({
            iconName: 'log-out',
            label: 'Авторизоваться',
            cb: cell => this.$modal.show('ozon-auth-modal', { id: cell.id })
          })
        }
      }

      if (row.marketplace_code === marketplaceCode.WILDBERRIES) {
        if (row.wb_inner_auth_state === wildberriesAuthStatuses.ACTIVE.key) {
          actions.push({
            iconName: 'log-out',
            label: 'Выйти',
            cb: async (cell) => await this.detachWildberriesShop(cell.id)
          })
        } else {
          actions.push({
            iconName: 'log-out',
            label: 'Авторизоваться',
            cb: cell => this.$modal.show('wildberries-auth-modal', { id: cell.id })
          })
        }
      }

      return actions
    }
  }
}
</script>

<style lang="stylus" scoped>
  .shops-list
    &__header
      display flex
      justify-content space-between
      align-items center
      padding-bottom 15px
      border-bottom 1px solid #e5e5e9

      @media (min-width 768px)
        margin-bottom 25px
        padding-bottom 0
        border-bottom none

    &__title
      font-size 18px
      margin-bottom 0

    &__actions
      display flex
      margin 0
      padding 0
      list-style none
      margin-left -10px

    &__action
      margin-left 10px

    &__image
      max-width 30px

    &__placeholder
      margin-top 30px

  .marketplace
    &__icon
      max-width 70px
      max-height 20px

    &__auth
      width 15px
      height 15px

      &.svg-icon--ok
        color #3cc13b

      &.svg-icon--close
        width 9px
        height 9px

    &__auth-error
      display flex

      >>> .svg-icon--info-fill
        color #c4c4c4

    &__auth-info
      margin-left 5px
</style>
