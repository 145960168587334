<template>
  <modal
    :name="modalName"
    height="auto"
    @before-open="setShopInfo"
  >
    <div
      v-if="shop"
      class="market-info"
    >
      <header class="market-info__header">
        <h2 class="market-info__heading">
          Магазин
        </h2>
        <close-button @click="closeModal" />
      </header>

      <validation-observer
        ref="form"
        v-slot="{ handleSubmit, invalid }"
      >
        <default-form
          @submit="handleSubmit(onSubmit)"
        >
          <dl
            class="market-info__property"
          >
            <dt class="market-info__property-name">
              Магазин
            </dt>
            <dd class="market-info__property-value">
              <x-input
                v-model="shop.shop"
              />
            </dd>
          </dl>

          <dl
            class="market-info__property"
          >
            <dt class="market-info__property-name">
              Маркетплейс
            </dt>
            <dd class="market-info__property-value">
              {{ shop.marketplace_name }}
            </dd>
          </dl>

          <dl
            v-for="field in editableCredentials"
            :key="field.name"
            class="market-info__property"
          >
            <dt class="market-info__property-name">
              {{ field.name }}
            </dt>
            <dd class="market-info__property-value">
              <x-form-item
                v-slot="{ classes, ...validationData }"
                ref="formitem"
                :name="field.name"
                :rules="field.rules"
                :vid="field.key"
              >
                <x-input
                  v-model="shop.credentials[field.key]"
                  v-bind="{ ...$attrs, ...validationData }"
                />
                <!-- :failed="isWildberriesTokenInvalid(field)" -->
              </x-form-item>
            </dd>
          </dl>

          <div
            v-if="isAliexpressShop"
            class="market-info__status"
          >
            <span class="market-info__property-name">
              Авторизация в Продвижении
            </span>
            <div
              v-if="wrongPass"
              class="market-info__auth"
            >
              <svg-icon
                class="market-info__icon"
                name="error-triangle"
              />
              <tooltip class="market-info__tooltip">
                Ошибка
                <svg-icon
                  class="market-info__icon"
                  name="info-fill"
                />

                <template #popover>
                  Неверный логин\пароль, пожалуйста пройдите авторизацию повторно
                </template>
              </tooltip>

              <x-btn
                ghost
                type="button"
                prepend="log-out"
                class="market-info__logout"
                @click="onLogin(shop.marketplace_code)"
              >
                Авторизоваться
              </x-btn>
            </div>

            <x-btn
              v-else-if="requires2FA"
              ghost
              type="button"
              prepend="log-out"
              class="market-info__2fa-login"
              @click="on2FAuth"
            >
              Пройти двуфакторную авторизацию
            </x-btn>

            <div
              v-else
              class="market-info__auth"
            >
              <div class="market-info__auth-status">
                <svg-icon
                  class="market-info__icon"
                  :name="shop.is_enable_ali_access_data ? 'ok': 'close'"
                />
                {{ shop.is_enable_ali_access_data ? 'Авторизован' : 'Не авторизован' }}
              </div>

              <x-btn
                ghost
                class="market-info__logout"
                type="button"
                prepend="log-out"
                @click="shop.is_enable_ali_access_data ? onLogout() : onLogin(marketplaceCodes.ALI)"
              >
                {{ shop.is_enable_ali_access_data ? 'Выйти' : 'Авторизоваться' }}
              </x-btn>
            </div>
          </div>

          <div
            v-if="isOzonShop"
            class="market-info__status"
          >
            <span class="market-info__property-name">
              Авторизация
            </span>

            <div class="market-info__auth">
              <div class="market-info__auth-status">
                <svg-icon
                  v-if="ozonStatusIcon"
                  :name="ozonStatusIcon"
                  class="market-info__icon"
                />
                {{ ozonStatusName }}
              </div>

              <x-btn
                ghost
                class="market-info__logout"
                type="button"
                prepend="log-out"
                @click="isOzonAuthenticated ? onLogout() : onLogin(marketplaceCodes.OZON)"
              >
                {{ isOzonAuthenticated ? 'Выйти' : 'Авторизоваться' }}
              </x-btn>
            </div>
          </div>

          <div
            v-if="isWildberriesShop"
            class="market-info__status"
          >
            <span class="market-info__property-name">
              Авторизация
            </span>

            <div class="market-info__auth">
              <div class="market-info__auth-status">
                <svg-icon
                  v-if="wbStatusIcon"
                  :name="wbStatusIcon"
                  class="market-info__icon"
                />
                {{ wbStatusName }}
              </div>

              <x-btn
                ghost
                class="market-info__logout"
                type="button"
                prepend="log-out"
                @click="isWildberriesAuthenticated ? onLogout() : onLogin(marketplaceCodes.WILDBERRIES)"
              >
                {{ isWildberriesAuthenticated ? 'Выйти' : 'Авторизоваться' }}
              </x-btn>
            </div>
          </div>

          <div
            v-if="isAliexpressShop"
            class="market-info__property"
          >
            <span class="market-info__property-name">
              Авторизационный токен
            </span>
            <div class="market-info__token-status">
              <badge
                :status-list="tokenStatuses"
                :status="shop.has_valid_token ? 'actual' : 'wrong_token'"
              />

              <refresh-button
                v-if="!shop.has_valid_token && !isRefreshProcessed"
                ghost
                class="market-info__refresh-btn"
                @click="onRefreshToken"
              />
              <loader
                v-if="isRefreshProcessed"
                mini
              />
            </div>
          </div>

          <div class="market-info__status">
            <span class="market-info__property-name">
              Сбор данных для аналитической отчетности
            </span>
            <checkbox
              v-model="shop.sync_daily_report"
              rounded
              class="market-info__checkbox"
            >
              {{ shop.sync_daily_report ? 'Активен' : 'Не активен' }}
            </checkbox>
          </div>

          <div class="market-info__status">
            <span class="market-info__property-name">
              Статус
            </span>
            <checkbox
              v-model="shop.is_active"
              rounded
              class="market-info__checkbox"
            >
              {{ shop.is_active ? 'Активен' : 'Не активен' }}
            </checkbox>
          </div>
          <market-info-buttons
            :disabled="invalid"
            :is-loading="isLoading"
            @dismiss="dismissChanges"
            @close="closeModal"
          />
        </default-form>
      </validation-observer>
    </div>
  </modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Badge from '@/components/Common/Badge.vue'
import Loader from '@/components/Common/Loader.vue'
import SvgIcon from '@/components/Common/SvgIcon.vue'
import Tooltip from '@/components/Common/Tooltip.vue'
import Checkbox from '@/components/Interface/Checkbox.vue'
import CloseButton from '@/components/Interface/CloseButton.vue'
import DefaultForm from '@/components/Interface/Form.vue'
import RefreshButton from '@/components/Interface/RefreshButton.vue'
import MarketInfoButtons from '@/components/Marketplaces/Modals/MarketInfoButtons.vue'
import marketplaceCodes from '@/constants/marketplace-codes'
import { authStatuses, editableFields, ozonAuthStatuses, wildberriesAuthStatuses } from '@/constants/shops'
import statuses from '@/constants/statuses'

const { mapActions, mapGetters } = createNamespacedHelpers('marketplaces')

export default {
  components: {
    DefaultForm,
    Checkbox,
    Badge,
    SvgIcon,
    CloseButton,
    MarketInfoButtons,
    Tooltip,
    RefreshButton,
    Loader
  },

  props: {
    isLoading: Boolean,
    invalidMessage: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data () {
    return {
      modalName: 'market-info',
      id: null,
      tokenStatuses: {
        actual: 'Верный',
        wrong_token: 'Просрочен'
      },
      isRefreshProcessed: false,
      marketplaceCodes,
      wildberriesAuthStatuses
    }
  },

  watch: {
    'invalidMessage': {
      handler (err) {
        console.log('err', err)
        if (!err) return
        // eslint-disable-next-line
        if (err.keys) {
          err.keys.forEach(fieldKey => {
            const field = this.editableCredentials.find(field => field.key === fieldKey)
            if (!field) return
            this.$refs.form.setErrors({
              [fieldKey]: `${field.name} неверный.`
            })
          })
        } else {
          console.log('this.editableCredentials', this.editableCredentials)
          this.editableCredentials.forEach(field => {
            this.$refs.form.setErrors({
              [field.name]: `${field.key} неверный.`
            })
          })
        }
      }
    }
  },

  computed: {
    ...mapGetters(['getShopById']),

    statusList () {
      return Object.values(statuses)
    },

    authStatus () {
      return this.shop.authorization_status
    },

    shop () {
      const shop = this.getShopById(this.id)

      if (shop) {
        return { ...shop, shop: shop.shop.name }
      }

      return null
    },

    isAliexpressShop () {
      return this.shop.marketplace_code === marketplaceCodes.ALI
    },

    isOzonShop () {
      return this.shop.marketplace_code === marketplaceCodes.OZON
    },

    isWildberriesShop () {
      return this.shop.marketplace_code === marketplaceCodes.WILDBERRIES
    },

    isWildberriesAuthenticated () {
      return this.shop.wb_inner_auth_state === wildberriesAuthStatuses.ACTIVE.key
    },

    wbStatusIcon () {
      return wildberriesAuthStatuses[this.shop.wb_inner_auth_state]
        ? wildberriesAuthStatuses[this.shop.wb_inner_auth_state].icon
        : null
    },

    wbStatusName () {
      return wildberriesAuthStatuses[this.shop.wb_inner_auth_state]
        ? wildberriesAuthStatuses[this.shop.wb_inner_auth_state].name
        : 'Статус неизвестен'
    },

    isOzonAuthenticated () {
      return this.shop.ozon_inner_auth_state === ozonAuthStatuses.ACTIVE.key
    },

    ozonStatusIcon () {
      return ozonAuthStatuses[this.shop.ozon_inner_auth_state]
        ? ozonAuthStatuses[this.shop.ozon_inner_auth_state].icon
        : null
    },

    ozonStatusName () {
      return ozonAuthStatuses[this.shop.ozon_inner_auth_state]
        ? ozonAuthStatuses[this.shop.ozon_inner_auth_state].name
        : 'Статус неизвестен'
    },

    requires2FA () {
      return this.authStatus === authStatuses.requires2FA
    },

    wrongPass () {
      return this.authStatus === authStatuses.wrongPassword
    },

    editableCredentials () {
      return editableFields[this.shop.marketplace_code]
    },

    computedInvalidMessageFromServer () {
      return this.invalidMessage
    }
  },

  methods: {
    ...mapActions([
      'logoutMarketplace',
      'detachWildberriesShop',
      'detachOzonShop',
      'request2FACode',
      'getAliTokenUpdateUrl'
    ]),

    setShopInfo ({ params }) {
      this.id = params.cell.id
    },

    dismissChanges () {
      this.closeModal()
    },

    closeModal () {
      this.$modal.hide(this.modalName)
    },

    onSubmit () {
      this.$emit('save-changes', this.shop)
    },

    async onLogout () {
      if (this.isWildberriesShop) {
        await this.detachWildberriesShop(this.id)
      } else if (this.isOzonShop) {
        await this.detachOzonShop(this.id)
      } else {
        await this.logoutMarketplace(this.id)
      }
    },

    async onRefreshToken () {
      try {
        this.isRefreshProcessed = true
        const url = await this.getAliTokenUpdateUrl({
          id: this.id,
          page_url: location.href
        })
        if (url) {
          window.location = url
        }
      } finally {
        this.isRefreshProcessed = false
      }
    },

    isWildberriesTokenInvalid (field) {
      return field.key === 'wildberries_stats_token' && !this.shop.credentials[field.key]
    },

    onLogin (code) {
      this.$modal.show(`${code}-auth-modal`, { id: this.id })
    },

    on2FAuth () {
      this.$modal.show('2fa-modal', { id: this.id })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .market-info
    padding 30px 40px
    font-size 14px

    @media (max-width 768px)
      padding 16px
      padding-top 65px

    &__header
      display flex
      align-items center
      min-height 36px

    &__heading
      font-size 20px

    &__property,
    &__status
      display grid
      grid-template-columns 40% 60%
      align-items center
      padding-top 20px

      @media (max-width 768px)
        display flex
        justify-content space-between

    &__property-name
      @media (max-width 768px)
        color rgba(#2c2c37, 0.5)

    &__property-value
      margin-left 8px

    &__icon
      width 15px
      height 15px
      margin-right 8px

      &.svg-icon--close
        width 9px
        height 9px

      &.svg-icon--ok
        color #3cc13b

      &.svg-icon--info-fill
        margin-right 0
        margin-left 5px
        color #c4c4c4

    &__tooltip
      >>> .trigger
        display flex !important
        align-items center

    &__auth
      display flex
      align-items center
      white-space nowrap

      @media (max-width 768px)
        flex-wrap wrap
        justify-content flex-end

    &__auth-status,
    &__token-status
      display flex
      align-items center

    &__refresh-btn
      margin-left 10px

    &__logout
      margin-left 13px

    &__2fa-login
      min-height 24px

    &__property-shop
      @media (max-width 768px)
        flex-wrap wrap

      .market-info__property-value
        width 100%
        margin-top 5px

    &__checkbox
      >>>.checkbox__text
        color #2c2c37

      >>>.checkbox__in
        display flex
        align-items center

  .vm--container
    >>> .vm--modal
      overflow unset
</style>
