<template>
  <x-dialog
    no-actions
    siz="m"
  >
    <not-permit-by-tariff />
  </x-dialog>
</template>

<script>
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff'
export default {
  name: 'NotPermitModal',
  components: { NotPermitByTariff }
}
</script>

<style scoped>

</style>
