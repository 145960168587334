export const TARIFF = {
  TEST: {
    key: 'test'
  },
  FREE: {
    key: 'free'
  },
  TEMPORARY: {
    key: 'temporary'
  },
  BASE: {
    key: 'base'
  },
  BASE2: {
    key: 'base2'
  },
  PRO: {
    key: 'pro'
  },
  CUSTOM: {
    key: 'custom'
  }
}
