<template>
  <x-alert
    v-if="isShown"
    type="success"
  >
    <span>
      На тарифе «{{ currentTariffName }}» отображаются данные для <strong>{{ shopAmountPlural }}</strong>. Для просмотра данных по остальным магазинам
      <x-link
        :to="TARIFF_PATH"
      >
        перейдите на другой тариф
      </x-link>
    </span>
  </x-alert>
</template>

<script>
import ROUTES from '@/router/ROUTES'
import createCountFormatter from '@/utils/count-formatter'

export default {
  name: 'ShopLimitAlert',
  data: () => ({
    TARIFF_PATH: ROUTES.TARIFFS.path

  }),
  computed: {
    isShown () {
      return typeof this.$user.getShopsLimit() === 'number'
    },

    currentTariffName () {
      return this.$user.getCurrentTariffName()
    },

    shopAmountPlural () {
      const getPlural = createCountFormatter({ few: 'магазинов', one: 'магазина', two: 'магазинов' })
      return this.$user.getShopsLimit() + ' ' + getPlural(this.$user.getShopsLimit())
    }
  }
}
</script>

<style scoped>

</style>
