<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    width="90%"
    height="auto"
    :adaptive="true"
    :click-to-close="false"
    @before-open="onBeforeOpen"
    @closed="onClosed"
  >
    <div class="modal-container">
      <close-button
        v-if="captcha_error"
        class="confirm-modal__close"
        @click="$modal.hide(modalName)"
      />
      <validation-observer
        v-if="!isFormSent"
        v-slot="{ handleSubmit }"
      >
        <heading
          level="3"
          class="mt-6"
        >
          {{ title }}
        </heading>

        <the-form
          class="form_content_connect-marketplace"
          @submit="handleSubmit(onSubmit)"
        >
          <fieldset class="form-fields-group">
            <ul class="form-fields-list">
              <template v-if="step === 1">
                <li class="form-fields-list__item form-fields-list__item_content_desc">
                  Введите номер телефона магазина на Wildberries.
                </li>

                <li class="form-fields-list__item">
                  <phone-field
                    v-model="phone"
                    placeholder="+7 987 654 32 10"
                  />
                </li>

                <x-btn
                  v-if="isCodeRequested"
                  text
                  @click="inputeCode"
                >
                  Ввести код
                </x-btn>
              </template>

              <template v-if="step === 2 || step === 4">
                <li
                  v-if="step === 2 "
                  class="form-fields-list__item form-fields-list__item_content_desc"
                >
                  Введите код полученный в СМС сообщении или в приложении Wildberries.
                </li>
                <li
                  v-else
                  class="form-fields-list__item form-fields-list__item_content_desc"
                >
                  Введите второй код с почты.
                </li>

                <li class="form-fields-list__item">
                  <x-form-item
                    v-slot="validationData"
                    label="Код"
                    name="Код"
                    rules="required"
                  >
                    <x-input
                      v-model="code"
                      v-mask="'######'"
                      v-bind="validationData"
                      placeholder="Код"
                    />
                  </x-form-item>
                </li>

                <p v-if="resendText">
                  {{ resendText }}
                </p>
                <x-btn
                  v-else
                  type="button"
                  text
                  @click="resendCode"
                >
                  Отправить повторно
                </x-btn>
              </template>

              <template v-if="step === 3">
                <li class="form-fields-list__item">
                  <x-form-item
                    v-slot="validationData"
                    label="Выберите магазин"
                    name="Выберите магазин"
                    rules="required"
                  >
                    <x-select
                      v-model="selectedShop"
                      :options="shops"
                      track-by="id"
                      label="name"
                      v-bind="validationData"
                      placeholder="Магазин"
                    />
                  </x-form-item>
                </li>
              </template>

              <template v-if="step === 5">
                <li v-if="captcha_error" class="form-fields-list__item form-fields-list__item_content_captcha">
                  <p class="error--text">{{ captcha_error }}</p>
                </li>
                <li class="form-fields-list__item form-fields-list__item_content_captcha">
                  <x-img
                    v-if="imgUrl"
                    :src="imgUrl"
                    size="400"
                  />
                  <x-btn
                    type="button"
                    text
                    prepend="refresh-right"
                    @click="getCaptcha"
                  >
                    Обновить код
                  </x-btn>
                </li>

                <li class="form-fields-list__item">
                  <x-form-item
                    v-slot="validationData"
                    label=""
                    name=""
                  >
                    <x-input
                      v-model="captcha_code"
                      v-bind="validationData"
                      placeholder="Код из капчи"
                    />
                  </x-form-item>
                </li>
              </template>

              <template v-if="step === 6">
                {{resendCapchaText}}
                <x-btn
                  v-if="isSendingCapcha"
                  type="button"
                  text
                  prepend="refresh-right"
                  @click="getCaptcha"
                >
                  Обновить капчу
                </x-btn>
              </template>

              <li
                v-if="step !== 6"
                class="form-fields-list__item form-fields-list__item_content_submit"
              >
                <ul class="modal-container__actions">
                  <li class="modal-container__action">
                    <x-btn
                      type="button"
                      outline
                      block
                      @click="$modal.hide(modalName)"
                    >
                      Отмена
                    </x-btn>
                  </li>

                  <li class="modal-container__action">
                    <x-btn
                      type="submit"
                      block
                      :disabled="isSubmitDisabled"
                      :loading="isLoading"
                    >
                      Отправить
                    </x-btn>
                  </li>
                </ul>
              </li>
            </ul>
          </fieldset>
        </the-form>
      </validation-observer>

      <success-connection
        v-else
        heading="Доступ получен!"
        text=""
        @click="onFinishClick"
      />
    </div>
  </modal>
</template>

<script>
import Heading from '@/components/Common/Heading'
import TheForm from '@/components/Interface/Form.vue'
import PhoneField from '@/components/Interface/PhoneField'
import SuccessConnection from '@/components/Marketplaces/ConnectForms/SuccessConnection.vue'
import CloseButton from '@/components/Interface/CloseButton.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('marketplaces')

const baseTimerDuration = 60

const capchaTimerDuration = 7

export default {
  components: {
    Heading,
    PhoneField,
    TheForm,
    SuccessConnection,
    CloseButton
  },

  data () {
    return {
      modalName: 'wildberries-auth-modal',
      id: null,
      isCodeRequested: false,
      isSendingCapcha: false,
      isFormSent: false,
      isLoading: false,
      phone: '+7',
      code: '',
      step: 1,
      shops: [],
      selectedShop: null,
      timer: baseTimerDuration,
      capchaTimer: capchaTimerDuration,
      imgUrl: null,
      captcha_code: '',
      captcha_error: ''
    }
  },

  watch: {
    step (value) {
      if (value === 2) {
        this.isCodeRequested = true
        this.startResendTimer()
      }
      if (value === 3) {
        this.isCodeRequested = false
        this.getShopsList()
      }
      if (value === 6) {
        this.isSendingCapcha = false
        this.startCapchaResendTimer()
      }
    }
  },

  computed: {
    isSubmitDisabled () {
      if (this.step === 1) return this.phone.length < 18
      if (this.step === 2) return this.code.length < 6
      if (this.step === 3) return this.selectedShop === null
      if (this.step === 5) return this.captcha_code.length < 6
      return false
    },

    computedPhone () {
      return this.phone.replace(/[+()-/\s]/g, '')
    },

    resendText () {
      return this.timer < baseTimerDuration ? `Запросить код повторно можно через ${this.timer} секунд` : ''
    },

    resendCapchaText () {
      return this.capchaTimer < capchaTimerDuration ? `Запросить код повторно можно через ${this.capchaTimer} секунд` : ''
    },

    title () {
      const title = this.step === 5 ? '' : 'Доступ к личному кабинету'
      return this.step === 3 ? 'Выбор магазина' : title
    }
  },

  methods: {
    ...mapActions([
      'requestWildberries2FACode',
      'sendWildberries2FACode',
      'sendWildberriesAuthByTfaCode',
      'getWildberriesShopList',
      'attachWildberriesShop'
    ]),

    async onBeforeOpen (e) {
      if (e.params) {
        const { id } = e.params

        if (id) this.id = id
      }
    },

    onClosed () {
      this.code = ''
      this.sessionId = ''
      this.step = 1
      this.shops = []
      this.selectedShop = null
      this.isFormSent = false
      this.captcha_code = ''
      this.captcha_error = ''
    },

    startResendTimer () {
      const interval = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          clearInterval(interval)
          this.timer = baseTimerDuration
        }
      }, 1000)
    },

    startCapchaResendTimer () {
      const interval = setInterval(() => {
        this.capchaTimer--
        if (this.capchaTimer <= 0) {
          clearInterval(interval)
          this.capchaTimer = capchaTimerDuration
          this.isSendingCapcha = true
        }
      }, 1000)
    },

    onSubmit () {
      if (this.id) {
        if (this.step === 1) {
          this.getCaptcha()
        }

        if (this.step === 5) {
          this.requestCode()
        }

        if (this.step === 2) {
          this.sendCode()
        }

        if (this.step === 4) {
          this.sendWildberriesAuthByTfa()
        }

        if (this.step === 3) {
          this.attachShop()
        }
      }
    },

    inputeCode () {
      this.step = 2
      this.code = ''
    },

    async resendCode () {
      await this.requestCode()
      this.startResendTimer()
    },

    async sendWildberriesAuthByTfa () {
      this.isLoading = true
      const result = await this.sendWildberriesAuthByTfaCode({ id: this.id, user: this.computedPhone, tfa_code: this.code, session_id: this.sessionId })
      this.step = result[0]
      this.sessionId = result[1]
      this.isLoading = false
    },

    async getCaptcha () {
      this.isLoading = true
      this.captcha_error = ''
      this.captcha_code = ''
      const result = await this.requestWildberries2FACode({ id: this.id, user: this.computedPhone })
      this.step = 1
      this.step = result[0]
      this.imgUrl = result[1]
      this.isLoading = false
    },

    async requestCode () {
      this.isLoading = true
      this.captcha_error = ''
      const result = await this.requestWildberries2FACode({ id: this.id, user: this.computedPhone, captcha_code: this.captcha_code })
      this.step = 1
      this.step = result[0]
      this.imgUrl = result[1]
      this.sessionId = result[2]
      this.captcha_error = this.imgUrl ? 'Неверно указан код' : ''
      this.captcha_code = ''
      this.isLoading = false
    },

    async sendCode () {
      this.isLoading = true
      const result = await this.sendWildberries2FACode({ id: this.id, user: this.computedPhone, sms_code: this.code, session_id: this.sessionId })
      this.step = result[0]
      this.sessionId = result[1]
      if (this.step === 4) {
        this.code = ''
      }
      this.isLoading = false
    },

    async getShopsList () {
      this.isLoading = true
      this.shops = await this.getWildberriesShopList({ user: this.computedPhone })
      this.isLoading = false
    },

    async attachShop () {
      this.isLoading = true
      this.isFormSent = await this.attachWildberriesShop({ id: this.id, user: this.computedPhone, wildberries_shop_id: this.selectedShop.id })
      this.isLoading = false
    },

    onFinishClick () {
      this.phone = ''
      this.isCodeRequested = false
      this.$modal.hide(this.modalName)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-container
    max-width 390px
    margin 0 auto
    padding 20px 15px 60px
    box-sizing border-box

    @media (min-width 768px)
      padding 20px 0 60px

    &__actions
      display flex
      justify-content space-between
      margin 0
      padding 0
      list-style none

    &__action
      flex-basis 48%

  /deep/ .vm--modal
    overflow visible
</style>
