export default Object.freeze({
  active: 'Активен',
  disable: 'Не активен',
  PAUSE: 'На паузе',
  DONE: 'Завершен',
  EXPIRED: 'Токен просрочен',
  actual: 'Актуальный',
  error: 'Ошибка',
  ERROR: 'Ошибка',
  not_import: 'Не загружен',
  not_actual: 'Не актуальный',
  in_progress: 'В работе',
  PROCESSING: 'В работе',
  new: 'Новый',
  NEW: 'Новый',
  ready_for_import: 'Ожидает запуска',
  done: 'Завершен',
  FINISHED: 'Завершен',
  IN_WAREHOUSE: 'На складе',
  wrong_token: 'Неверный токен',
  deleted: 'Удален',
  stopped: 'Остановлена',
  done_task: 'Завершена',
  draft: 'Не запущена'
})

export const statusStyles = Object.freeze({
  active: { backgroundColor: '#ceefce' },
  done_task: { backgroundColor: '#ceefce' },
  wait_seller_send_goods: { backgroundColor: '#ceefce' },
  disable: { backgroundColor: '#fbcdcd' },
  pause: { backgroundColor: '#fceec6' },
  expired: { backgroundColor: '#ecdeff' },
  actual: { backgroundColor: '#ceefce' },
  error: { color: '#fff', backgroundColor: '#f03838' },
  deleted: { backgroundColor: '#fbcdcd' },
  not_actual: { backgroundColor: '#fceec7' },
  not_import: { backgroundColor: '#fbcdcd' },
  wait_buyer_accept_goods: { backgroundColor: '#d6cfff' },
  awaiting_packaging: { backgroundColor: '#f9dd8d' },
  stopped: { backgroundColor: '#f9dd8d' },
  in_issue: { backgroundColor: '#fbcdcd' },
  cancelled: { backgroundColor: '#fec79c' },
  delivered: { backgroundColor: '#dabdff' },
  onselling: { backgroundColor: '#ceefce' },
  offline: { backgroundColor: '#fbcdcd' },
  editingrequired: { backgroundColor: '#fceec6' },
  auditing: { backgroundColor: '#fec79c' },
  draft: { backgroundColor: '#fec79c' },
  wrong_token: { backgroundColor: '#f9dd8d' }
})

export const statusIcons = {
  error: 'error-triangle',
  ERROR: 'error-triangle',
  in_progress: '',
  PROCESSING: 'loader--mini',
  new: 'bell',
  NEW: 'bell',
  DONE: 'success',
  FINISHED: 'success-fill'
}
