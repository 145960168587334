<template>
  <x-form-item
    v-slot="{ errors, ...validationData }"
    label="Телефон"
    name="Телефон"
    rules="required|digits-length:11"
  >
    <x-input
      id="phone"
      v-mask="'+7 (###) ###-##-##'"
      :value="value"
      :placeholder="$attrs.placeholder || 'Введите телефон'"
      v-bind="{ ...$attrs, ...validationData }"
      :failed="!!errors.length || !!errorMessage"
      @input="$emit('input', $event)"
    />
    <span class="x-form-item__errors">
      {{ errors.length ? '' : errorMessage }}
    </span>
  </x-form-item>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
