<template>
  <div
    class="badge"
    :style="statusStyle"
  >
    <tooltip>
      <svg-icon
        v-if="icon"
        :name="icon"
        class="badge__icon"
      />
      {{ statusText }}

      <template
        v-if="statusTooltip"
        #popover
      >
        <template v-if="tooltipMessage">
          {{ statusTooltip(tooltipMessage) }}
        </template>

        <template v-else>
          {{ statusTooltip }}
        </template>
      </template>
    </tooltip>
  </div>
</template>

<script>
import Statuses, {
  statusStyles
} from '@/constants/statuses'
import Tooltip from '@/components/Common/Tooltip.vue'

export default {
  components: { Tooltip },
  props: {
    status: {
      type: String,
      default: 'default'
    },
    statusList: {
      type: Object,
      default: () => ({})
    },
    statusTooltips: {
      type: Object,
      default: () => ({})
    },
    icon: {
      type: String,
      default: ''
    },
    tooltipMessage: {
      type: String,
      default: ''
    },
    defaultStyle: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    statusText () {
      return this.statusList[this.status] || Statuses[this.status]
    },

    statusStyle () {
      if (this.icon || !this.status) {
        return
      }

      return statusStyles[this.status.toLowerCase()] || this.defaultStyle
    },

    statusTooltip () {
      return this.statusTooltips[this.status]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .badge
    display inline-flex
    align-items center
    padding 1px 6px
    border-radius 20px

    &__icon
      width 13px
      height 13px
      margin-right 5px

      &.svg-icon--pause
        fill #f9dd8d

  >>>.v-popover
    .trigger
      display flex !important
      align-items center
</style>
