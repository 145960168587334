<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    :scrollable="$currentDevice.isMobile"
    width="90%"
    height="auto"
    :adaptive="true"
    @closed="onClosed"
  >
    <div class="modal-container">
      <validation-observer
        v-if="!formSended"
        v-slot="{ handleSubmit }"
      >
        <default-form @submit="handleSubmit(onSubmit)">
          <fieldset class="form-fields-group">
            <legend class="form-fields-group__title modal-container__header">
              Создание магазина
            </legend>

            <ul class="form-fields-list">
              <li class="form-fields-list__item form-fields-list__item_content_desc">
                Менеджеры {{ companyDetails.name }} готовы оперативно связаться с вами для помощи в создании магазина
              </li>

              <li class="form-fields-list__item">
                Выберите маркетплейсы, на которых вы хотите открыть магазины, и укажите, по какому телефону с вами можно связаться?
              </li>

              <li class="form-fields-list__item">
                <x-form-item
                  v-slot="{ errors }"
                  rules="required"
                  name="Маркетплейс"
                >
                  <div class="modal-container__marketplaces">
                    <checkbox-group
                      v-model="form.marketplace"
                      class="modal-container__checkboxgroup"
                      :items="marketplaces"
                      @change="f => form.marketplace = f"
                    >
                      <template #default="{ item }">
                        <div class="modal-container__marketplace">
                          {{ item.label }}
                        </div>
                      </template>
                    </checkbox-group>

                    <form-error
                      v-if="errors"
                      :text="errors[0]"
                    />
                  </div>
                </x-form-item>
              </li>

              <li class="form-fields-list__item">
                <x-form-item
                  v-slot="validationData"
                  rules="required"
                  name="Номер телефона"
                  label="Введите номер телефона"
                >
                  <x-input
                    v-model="form.phone"
                    v-mask="'+7 (###) ###-##-##'"
                    v-bind="validationData"
                    placeholder="+ 7"
                  />
                </x-form-item>
              </li>

              <li class="form-fields-list__item form-fields-list__item_content_submit">
                <x-btn
                  type="submit"
                  block
                  :loading="isLoading"
                  @click="onConsultation"
                >
                  Получить консультацию
                </x-btn>
              </li>
            </ul>
          </fieldset>
        </default-form>
      </validation-observer>

      <success
        v-else
        class="modal-container__success"
      >
        <h3 class="modal-container__heading">
          Запрос отправлен!
        </h3>
        <p class="modal-container__text">
          Мы свяжемся с вами в ближайшее время
        </p>
        <x-btn
          type="button"
          block
          @click="$modal.hide(modalName)"
        >
          Замечательно
        </x-btn>
      </success>

      <close-button
        @click="$modal.hide(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import marketplaces from '@/constants/marketplaces'
import DefaultForm from '@/components/Interface/Form.vue'
import CheckboxGroup from '@/components/Interface/CheckboxGroup.vue'
import FormError from '@/components/Interface/FormError.vue'
import Success from '@/components/Common/Success.vue'
import CloseButton from '@/components/Interface/CloseButton.vue'
import companyDetails from '@/plugins/companyDetails'

const { mapActions } = createNamespacedHelpers('marketplaces')
const { mapActions: mapTrackingActions } = createNamespacedHelpers('tracking')

export default {
  components: {
    DefaultForm,
    CheckboxGroup,
    FormError,
    Success,
    CloseButton
  },

  data () {
    return {
      modalName: 'create-store-request-modal',
      form: {
        marketplace: [],
        phone: ''
      },
      marketplaces,
      isLoading: false,
      formSended: false,
      companyDetails
    }
  },

  methods: {
    ...mapActions(['requestConsultation']),
    ...mapTrackingActions(['setEvent']),

    async onSubmit () {
      try {
        this.isLoading = true

        await this.requestConsultation({
          phone_number: this.form.phone,
          marketplace_codes: this.form.marketplace
        })

        this.formSended = true
      } finally {
        this.isLoading = false
      }
    },

    onClosed () {
      this.form.marketplace = []
      this.form.phone = ''
      this.formSended = false
    },

    onConsultation () {
      this.setEvent({
        name: 'mmp_shops_consultation',
        product: 'MMP',
        screen: 'mmp_shops',
        marketplace_code: this.form.marketplace.join()
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-container
    max-width 380px
    margin 0 auto
    padding 22px 0 60px
    font-size 14px
    color #54555f

    &__header
      margin-bottom 30px

    &__marketplaces
      margin-bottom 20px

    &__checkboxgroup
      display flex
      flex-wrap wrap

      >>> .checkbox-list__item
        flex-basis 50%

    &__success
      padding-top 73px
      padding-bottom 34px

    &__heading
      margin-bottom 10px

    &__text
      margin-bottom 20px
      font-family $font-family
      font-size 14px
      color #54555f
</style>
