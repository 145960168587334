<template>
  <ul
    class="checkbox-list"
  >
    <li
      v-for="(item, i) in items"
      :key="`${fName}_${fValue}_${i}`"
      class="checkbox-list__item"
    >
      <checkbox
        v-model="selected"
        :name="name"
        :value="item.value"
        @change="$emit('change', selected)"
      >
        <template>
          <slot :item="item">
            <span v-html="item[fName]" />
          </slot>
        </template>
      </checkbox>
    </li>
  </ul>
</template>

<script>
import Checkbox from './Checkbox.vue'

export default {

  components: {
    Checkbox
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: 'checkbox'
    },
    fName: {
      type: String,
      default: 'label'
    },
    fValue: {
      type: String,
      default: 'value'
    }
  },
  data () {
    return {
      selected: this.value || []
    }
  },

  watch: {
    value () {
      this.selected = this.value
    }
  }
}
</script>

<style lang="stylus" scoped>
  .checkbox-list
    margin 0
    padding 0
    list-style none

    &__item
      margin-bottom 7px

      &:last-child
        margin-bottom 0
</style>
