const marketplacesPromo = [
  {
    id: 1,
    img: require('@/assets/images/market/pic1.png'),
    title: 'На каком месте ваши товары в поисковой выдаче ?',
    to: `${process.env.VUE_APP_INTERNAL_ANALYTICS_URL}/reports`,
    link: 'Отчет по позициям<br> в «Товарной аналитике» …',
    mp_icons: ['wildberries', 'ozon'],
    metrics: 'mmp_shops_hint_analytics_reports_positions'
  },
  {
    id: 2,
    img: require('@/assets/images/market/pic2.png'),
    title: 'Как вывести свои карточки в топ ?',
    to: `${process.env.VUE_APP_INTERNAL_ANALYTICS_URL}/reports`,
    link: 'Анализ карточек<br> в «Товарной аналитике» …',
    mp_icons: [],
    metrics: 'mmp_shops_hint_analytics_products_content'
  },
  {
    id: 3,
    img: require('@/assets/images/market/pic3.png'),
    title: 'Где вы теряете деньги ?',
    to: `${process.env.VUE_APP_SELLER_CABINET_URL}/product/list`,
    link: 'Прогноз поставок<br> и оборачиваемости …',
    mp_icons: [],
    metrics: 'mmp_shops_hint_turnover_report'
  },
  {
    id: 4,
    img: require('@/assets/images/market/pic4.png'),
    title: 'По каким ценам продают конкуренты ?',
    to: process.env.VUE_APP_ORM_URL,
    link: 'Анализ<br> маркетплейсов …',
    mp_icons: ['wildberries', 'ozon'],
    metrics: 'mmp_shops_hint_orm'
  }
]

export default marketplacesPromo
